import { z } from 'zod';
import { dateToUTC } from 'shared/src/zod-utils';

// TODO[mk] - change to draft and finalised

const mediaPlatformEntityBase = z.object({
  id: z.string(),
  media_platform_id: z.number(),
  media_platform: z.object({ name: z.string(), id: z.number() }),
  entity_id: z.string(),
  raw_entity: z.object({}).catchall(z.any()).optional(),
  entity_type: z.string().optional(),
  entity_name: z.string()
});

export type MediaPlatformEntity = z.infer<typeof mediaPlatformEntityBase> & {
  parent: MediaPlatformEntity | null;
};

export const MediaPlatformEntity: z.ZodType<MediaPlatformEntity> = mediaPlatformEntityBase.extend({
  parent: z.lazy(() => MediaPlatformEntity.nullable())
});

export const discoveredMediaPlatformEntityBase = mediaPlatformEntityBase.omit({
  id: true,
  media_platform: true
});

export type DiscoveredMediaPlatformEntity = z.infer<typeof discoveredMediaPlatformEntityBase> & {
  parent?: DiscoveredMediaPlatformEntity | null;
};

export const DiscoveredMediaPlatformEntity: z.ZodType<DiscoveredMediaPlatformEntity> =
  discoveredMediaPlatformEntityBase.extend({
    parent: z.lazy(() => DiscoveredMediaPlatformEntity.optional().nullable())
  });

export const NewMediaPlatformEntity = mediaPlatformEntityBase
  .omit({ media_platform: true })
  .extend({
    parent_id: z.string().nullable().optional()
  });

export type NewMediaPlatformEntity = z.infer<typeof NewMediaPlatformEntity>;

export const UpdateMediaPlatformEntity = mediaPlatformEntityBase.omit({ media_platform: true });

export type UpdateMediaPlatformEntity = z.infer<typeof UpdateMediaPlatformEntity>;

export const MediaBuy = z.object({
  id: z.string(),
  // TODO this is redundant as its in the media_platform_entity
  name: z.string(),
  media_platform_id: z.number(),
  media_platform_entity: MediaPlatformEntity,
  start_date: dateToUTC,
  end_date: dateToUTC,
  budget: z.coerce.number()
});

export type MediaBuy = z.infer<typeof MediaBuy>;

export const DiscoveredMediaBuy = MediaBuy.omit({
  media_platform_entity: true,
  budget: true
}).extend({
  media_platform_entity: DiscoveredMediaPlatformEntity,
  budget: z.coerce.number().nullable().optional()
});

export type DiscoveredMediaBuy = z.infer<typeof DiscoveredMediaBuy>;

export const LineItemMediaBuy = z.object({
  id: z.string(),
  line_item_id: z.string(),
  media_buy_id: z.string()
});
export type LineItemMediaBuy = z.infer<typeof LineItemMediaBuy>;

export const NewMediaBuy = MediaBuy.omit({
  media_platform_entity: true
}).extend({
  line_item_id: z.string()
});
export type NewMediaBuy = z.infer<typeof NewMediaBuy>;

export const MediaBuyUpdate = MediaBuy.partial()
  .omit({ id: true, media_platform_entity: true })
  .extend({ id: z.string() });

export type MediaBuyUpdate = z.infer<typeof MediaBuyUpdate>;

export const MediaBuyChanges = z.union([
  z.object({
    type: z.literal('new'),
    data: NewMediaBuy
  }),
  z.object({
    type: z.literal('update'),
    data: MediaBuyUpdate
  })
]);
export type MediaBuyChanges = z.infer<typeof MediaBuyChanges>;
