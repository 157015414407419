import React from 'react';

export function CampaignIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM16 25.3333C21.1547 25.3333 25.3333 21.1547 25.3333 16C25.3333 10.8453 21.1547 6.66667 16 6.66667C10.8453 6.66667 6.66667 10.8453 6.66667 16C6.66667 21.1547 10.8453 25.3333 16 25.3333ZM16 26.6667C21.891 26.6667 26.6667 21.891 26.6667 16C26.6667 10.109 21.891 5.33333 16 5.33333C10.109 5.33333 5.33333 10.109 5.33333 16C5.33333 21.891 10.109 26.6667 16 26.6667ZM18.6667 16C18.6667 17.4728 17.4728 18.6667 16 18.6667C14.5272 18.6667 13.3333 17.4728 13.3333 16C13.3333 14.5272 14.5272 13.3333 16 13.3333C17.4728 13.3333 18.6667 14.5272 18.6667 16ZM20 16C20 18.2091 18.2091 20 16 20C13.7909 20 12 18.2091 12 16C12 13.7909 13.7909 12 16 12C18.2091 12 20 13.7909 20 16Z"
        fill="#4B5563"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9998 25.3333C21.1545 25.3333 25.3332 21.1547 25.3332 16C25.3332 10.8453 21.1545 6.66667 15.9998 6.66667C10.8452 6.66667 6.6665 10.8453 6.6665 16C6.6665 21.1547 10.8452 25.3333 15.9998 25.3333ZM18.6665 16C18.6665 17.4728 17.4726 18.6667 15.9998 18.6667C14.5271 18.6667 13.3332 17.4728 13.3332 16C13.3332 14.5272 14.5271 13.3333 15.9998 13.3333C17.4726 13.3333 18.6665 14.5272 18.6665 16ZM19.9998 16C19.9998 18.2091 18.209 20 15.9998 20C13.7907 20 11.9998 18.2091 11.9998 16C11.9998 13.7909 13.7907 12 15.9998 12C18.209 12 19.9998 13.7909 19.9998 16Z"
        fill="#4B5563"
      />
    </svg>
  );
}

export function LineItemIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM16 25.3333C21.1547 25.3333 25.3333 21.1547 25.3333 16C25.3333 10.8453 21.1547 6.66667 16 6.66667C10.8453 6.66667 6.66667 10.8453 6.66667 16C6.66667 21.1547 10.8453 25.3333 16 25.3333ZM16 26.6667C21.891 26.6667 26.6667 21.891 26.6667 16C26.6667 10.109 21.891 5.33333 16 5.33333C10.109 5.33333 5.33333 10.109 5.33333 16C5.33333 21.891 10.109 26.6667 16 26.6667ZM18.6667 16C18.6667 17.4728 17.4728 18.6667 16 18.6667C14.5272 18.6667 13.3333 17.4728 13.3333 16C13.3333 14.5272 14.5272 13.3333 16 13.3333C17.4728 13.3333 18.6667 14.5272 18.6667 16ZM20 16C20 18.2091 18.2091 20 16 20C13.7909 20 12 18.2091 12 16C12 13.7909 13.7909 12 16 12C18.2091 12 20 13.7909 20 16Z"
        fill="#CED4DA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0003 25.3333C21.155 25.3333 25.3337 21.1546 25.3337 16C25.3337 10.8453 21.155 6.66663 16.0003 6.66663C10.8457 6.66663 6.66699 10.8453 6.66699 16C6.66699 21.1546 10.8457 25.3333 16.0003 25.3333ZM18.667 16C18.667 17.4727 17.4731 18.6666 16.0003 18.6666C14.5276 18.6666 13.3337 17.4727 13.3337 16C13.3337 14.5272 14.5276 13.3333 16.0003 13.3333C17.4731 13.3333 18.667 14.5272 18.667 16ZM20.0003 16C20.0003 18.2091 18.2095 20 16.0003 20C13.7912 20 12.0003 18.2091 12.0003 16C12.0003 13.7908 13.7912 12 16.0003 12C18.2095 12 20.0003 13.7908 20.0003 16Z"
        fill="#4B5563"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6663 16C18.6663 17.4728 17.4724 18.6667 15.9997 18.6667C14.5269 18.6667 13.333 17.4728 13.333 16C13.333 14.5273 14.5269 13.3334 15.9997 13.3334C17.4724 13.3334 18.6663 14.5273 18.6663 16Z"
        fill="#4B5563"
      />
    </svg>
  );
}

export function MediaBuyIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM16 25.3333C21.1547 25.3333 25.3333 21.1547 25.3333 16C25.3333 10.8453 21.1547 6.66667 16 6.66667C10.8453 6.66667 6.66667 10.8453 6.66667 16C6.66667 21.1547 10.8453 25.3333 16 25.3333ZM16 26.6667C21.891 26.6667 26.6667 21.891 26.6667 16C26.6667 10.109 21.891 5.33333 16 5.33333C10.109 5.33333 5.33333 10.109 5.33333 16C5.33333 21.891 10.109 26.6667 16 26.6667ZM18.6667 16C18.6667 17.4728 17.4728 18.6667 16 18.6667C14.5272 18.6667 13.3333 17.4728 13.3333 16C13.3333 14.5272 14.5272 13.3333 16 13.3333C17.4728 13.3333 18.6667 14.5272 18.6667 16ZM20 16C20 18.2091 18.2091 20 16 20C13.7909 20 12 18.2091 12 16C12 13.7909 13.7909 12 16 12C18.2091 12 20 13.7909 20 16Z"
        fill="#CED4DA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0003 25.3333C21.155 25.3333 25.3337 21.1546 25.3337 16C25.3337 10.8453 21.155 6.66663 16.0003 6.66663C10.8457 6.66663 6.66699 10.8453 6.66699 16C6.66699 21.1546 10.8457 25.3333 16.0003 25.3333ZM18.667 16C18.667 17.4727 17.4731 18.6666 16.0003 18.6666C14.5276 18.6666 13.3337 17.4727 13.3337 16C13.3337 14.5272 14.5276 13.3333 16.0003 13.3333C17.4731 13.3333 18.667 14.5272 18.667 16ZM20.0003 16C20.0003 18.2091 18.2095 20 16.0003 20C13.7912 20 12.0003 18.2091 12.0003 16C12.0003 13.7908 13.7912 12 16.0003 12C18.2095 12 20.0003 13.7908 20.0003 16Z"
        fill="#CED4DA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6663 16C18.6663 17.4728 17.4724 18.6667 15.9997 18.6667C14.5269 18.6667 13.333 17.4728 13.333 16C13.333 14.5273 14.5269 13.3334 15.9997 13.3334C17.4724 13.3334 18.6663 14.5273 18.6663 16Z"
        fill="#4B5563"
      />
    </svg>
  );
}
