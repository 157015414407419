import { formatCurrency, formatDate, formatNumber, formatUnitCost } from './table-utils';
import React from 'react';
import { TooltipData } from './performance-chart';
import { UnitPriceType } from 'shared/src/line-item-channels';
import { getUnitName } from 'shared/src/line-item-utils';

type Props = {
  tooltipData: TooltipData;
  color: string;
};

export function ChartTooltip({ tooltipData, color }: Props) {
  const { displayMode, chartType, priceType } = tooltipData.data;
  const { value, target } = tooltipData.d;
  return (
    <div className="flex flex-col p-3 text-sm">
      <div className="flex items-center py-2">
        <div className="mr-1 h-[14px] w-[6px] rounded-full" style={{ background: color }} />
        <div className="text-gray-700">{formatName(tooltipData.data.name)}</div>
      </div>
      <div className="my-1 h-[2px] w-full bg-gray-300" />
      <div className="mb-1 mt-2">{`${displayMode === 'discrete' ? 'On' : 'Through'} ${formatDate(tooltipData.d.date)}`}</div>
      <PlannedVsActual
        actual={value}
        planned={target || 0}
        color={color}
        chartType={chartType}
        unitType={priceType}
      />
      <UnitCost tooltipData={tooltipData} />
    </div>
  );
}

function UnitCost({ tooltipData }: Omit<Props, 'color'>) {
  const { priceType, displayMode } = tooltipData.data;
  const { unitCostIncurred, unitCostIncurredCumulative } = tooltipData.d;
  const unitCost = displayMode === 'discrete' ? unitCostIncurred : unitCostIncurredCumulative;
  const adjUnitCost = priceType === 'CPM' ? (unitCost || 0) * 1000 : unitCost || 0;
  return (
    <TooltipItem
      label="Media Cost"
      value={`${formatUnitCost(adjUnitCost)} ${tooltipData.data.priceType}`}
    />
  );
}

type PlannedVsActualProps = {
  color: string;
  actual: number;
  planned: number;
  chartType: 'spend' | 'delivery';
  unitType: UnitPriceType['name'];
};

function PlannedVsActual({ color, actual, planned, chartType, unitType }: PlannedVsActualProps) {
  const actualLabel = getActualLabel(chartType);
  const plannedLabel = getPlannedLabel(chartType);
  const actualValue = chartType === 'spend' ? formatCurrency(actual) : formatNumber(actual);
  const plannedValue = chartType === 'spend' ? formatCurrency(planned) : formatNumber(planned);
  const suffix = chartType === 'delivery' ? ` ${getUnitName(unitType).full}` : '';

  return (
    <>
      <div className="flex w-full items-center justify-between py-2">
        <div className="flex items-center">
          <div className="mr-2 h-[2px] w-[20px] border-t-[2px]" style={{ borderColor: color }} />
          <div className="text-gray-500">{actualLabel}:</div>
        </div>
        <div className=" text-gray-700">{`${actualValue}${suffix}`}</div>
      </div>
      <div className="flex w-full items-center justify-between py-2">
        <div className="flex items-center">
          <div
            className="mr-2 h-[2px] w-[20px] border-t-[2px] border-dashed"
            style={{ borderColor: color }}
          />
          <div className="text-gray-500">{plannedLabel}:</div>
        </div>
        <div className=" text-gray-700">{`${plannedValue}${suffix}`}</div>
      </div>
    </>
  );
}

function getPlannedLabel(_: 'spend' | 'delivery') {
  return 'Planned';
}

function getActualLabel(chartType: 'spend' | 'delivery') {
  return chartType === 'spend' ? 'Spend' : 'Delivered';
}

function TooltipItem({ label, value }: { label: string; value: string }) {
  return (
    <div className="flex w-full items-center justify-between py-2">
      <div className=" text-gray-500">{label}:</div>
      <div className=" text-gray-700">{value}</div>
    </div>
  );
}

function formatName(name: string) {
  return name.length > 45 ? name.slice(0, 20) + '....' + name.slice(name.length - 15) : name;
}
