import React, { useEffect, useMemo } from 'react';
import { PerformanceChart } from '../../components/performance-chart';
import { DisplayMode } from '../../components/charts/display-mode-selector';
import { CombinedLineItem, CombinedMediaBuy } from '../../store/strategy-combiner';
import { convertMetrics, generateColorMap } from './media-buy-graph-utils';
import { MediaBuyGraphData } from '../../metrics/metrics-types';
import { MediaBuyMetric } from 'shared/src/metrics-types';
import { ChartType, Granularity } from '../../components/charts/chart-type-selector';

type Props = {
  data: MediaBuyMetric[];
  granularity: Granularity;
  lineItem: CombinedLineItem;
  mediaBuys: CombinedMediaBuy[];
  displayMode: DisplayMode;
  chartType: ChartType;
  setColorMap?: (colorMap: Record<string, string>) => void;
};

export function MediaBuyGraph({
  data,
  granularity,
  lineItem,
  mediaBuys,
  displayMode,
  chartType,
  setColorMap
}: Props) {
  const graphData = useMemo<MediaBuyGraphData[]>(() => {
    return convertMetrics(data, lineItem, mediaBuys, displayMode, chartType, granularity);
  }, [data, lineItem, mediaBuys, displayMode, chartType, granularity]);

  useEffect(() => {
    if (setColorMap) setColorMap(generateColorMap(graphData));
  }, [graphData, setColorMap]);

  if (!data) return null;

  return (
    <div className="flex w-full min-w-0 flex-1 flex-col">
      <PerformanceChart graphData={graphData} chartType={chartType} displayMode={displayMode} />
    </div>
  );
}
