import { createTRPCClient, httpLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import superjson from 'superjson';
import { AppRouter } from '@flights/backend/src/api/trpc/app-router';
import { UTCDate } from '@date-fns/utc';

// Custom serialization for UTCDate
superjson.registerCustom<UTCDate, string>(
  {
    isApplicable: (value): value is UTCDate => value instanceof UTCDate,
    serialize: value => value.toString(), // Serialize to ISO string or custom format
    deserialize: value => new UTCDate(value) // Convert back to UTCDate
  },
  'UTCDate'
);

export const trpc = createTRPCReact<AppRouter>();

// Used when we need to make a backend request in an imperative way
export const client = createTRPCClient<AppRouter>({
  links: [httpLink({ url: '/api', transformer: superjson })]
});
