import { useEffect, useState } from 'react';
import { client } from '../../utils/trpc-client';
import { LineItemMetric, MediaBuyMetric } from 'shared/src/metrics-types';
import { Granularity } from '../../components/charts/chart-type-selector';

export function useMediaBuyMetrics(media_buy_id: string, bucket: Granularity) {
  const [mediaBuyMetrics, setMediaBuyMetrics] = useState<MediaBuyMetric[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        setMediaBuyMetrics(await client.mediaBuyMetrics.query({ media_buy_id, bucket }));
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [bucket, media_buy_id]);
  return { data: mediaBuyMetrics, loading };
}

export function useLineItemMetrics(line_item_id: string, bucket: Granularity) {
  const [mediaBuyMetrics, setMediaBuyMetrics] = useState<MediaBuyMetric[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        setMediaBuyMetrics(await client.lineItemMetrics.query({ line_item_id, bucket }));
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [bucket, line_item_id]);

  return { data: mediaBuyMetrics, loading };
}

export function useCampaignMetrics(campaign_id: string) {
  const [lineItemMetrics, setLineItemMetrics] = useState<LineItemMetric[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        setLineItemMetrics(await client.campaignMetrics.query({ campaign_id, bucket: 'day' }));
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [campaign_id]);

  return { data: lineItemMetrics, loading };
}
