import { HeroMetric, HeroMetricsContainer } from '../../components/hero-metrics';
import React from 'react';
import { CampaignMetrics } from '../../metrics/metrics-types';

type Props = { metrics: CampaignMetrics };

export function CampaignHeroMetrics({ metrics }: Props) {
  const {
    deliveredRevenue,
    currentPlannedRevenue,
    mediaSpend,
    currentPlannedSpend,
    currentMargin,
    targetMargin,
    revenuePacing,
    spendPacing
  } = metrics;

  return (
    <HeroMetricsContainer>
      <HeroMetric
        primary={{ name: 'Revenue Pacing', value: revenuePacing }}
        other={[
          { type: 'currency', name: `Revenue`, value: deliveredRevenue },
          { type: 'currency', name: 'On-pace target', value: currentPlannedRevenue }
        ]}
      />
      <HeroMetric
        primary={{ name: 'Spend Pacing', value: spendPacing }}
        other={[
          { type: 'currency', name: `Media spend`, value: mediaSpend },
          { type: 'currency', name: 'On-pace spend', value: currentPlannedSpend }
        ]}
      />
      <HeroMetric
        primary={{ name: 'Margin', value: currentMargin }}
        other={[
          {
            type: 'margin',
            value: currentMargin,
            target: targetMargin
          },
          { type: 'percentage', name: 'Target Margin', value: targetMargin }
        ]}
      />
    </HeroMetricsContainer>
  );
}
