import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { CreateMediaBuyOptions, DraftStrategyChanges } from 'shared/src/types';
import { immer } from 'zustand/middleware/immer';
import {
  addLineItem,
  addMediaBuy,
  duplicateLineItems,
  removeLineItem,
  removeMediaBuy,
  resetChanges,
  updateLineItem
} from './state-fns';
import { LineItem, PartialLineItem } from 'shared/src/line-item-types';
import { CombinedLineItem } from './strategy-combiner';
import { createTestStorage, isTestMode } from './test-storage';

type LineItemPacingState = {
  open: boolean;
  lineItemId: string;
};

export type State = {
  campaigns: Record<string, DraftStrategyChanges>;
  lineItemPacing: LineItemPacingState | null;
  highlightedGraph: string | null;
};

export type Actions = {
  addLineItem: (lineItem: PartialLineItem) => unknown;
  updateLineItem: (update: PartialLineItem, original?: LineItem) => unknown;
  removeLineItem: (strategyId: string, lineItemId: string) => unknown;
  duplicateLineItems: (lineItems: CombinedLineItem[]) => unknown;
  resetChanges: (strategyId: string) => unknown;
  addMediaBuy: (options: CreateMediaBuyOptions) => unknown;
  removeMediaBuy: (mediaBuyId: string) => unknown;
  setLineItemPacing: (lineItemPacing: LineItemPacingState | null) => unknown;
  setHighlightedGraph: (graphId: string | null) => unknown;
};

export type StoreState = State & Actions;

export const useStore = create<
  StoreState,
  [['zustand/persist', never], ['zustand/devtools', never], ['zustand/immer', never]]
>(
  persist(
    devtools(
      immer((set, get) => ({
        campaigns: {},
        lineItemPacing: null,
        highlightedGraph: null,
        addLineItem: (lineItem: PartialLineItem) => set(state => void addLineItem(state, lineItem)),
        updateLineItem: (update: PartialLineItem, original?: LineItem) =>
          set(state => void updateLineItem(state, update, original)),
        removeLineItem: (strategyId: string, lineItemId: string) =>
          set(state => void removeLineItem(state, strategyId, lineItemId)),
        duplicateLineItems: (lineItems: CombinedLineItem[]) =>
          set(state => void duplicateLineItems(state, lineItems)),
        addMediaBuy: (options: CreateMediaBuyOptions) => set(state => addMediaBuy(state, options)),
        removeMediaBuy: (mediaBuyId: string) =>
          set(state => void removeMediaBuy(state, mediaBuyId)),
        resetChanges: (strategyId: string) => set(state => void resetChanges(state, strategyId)),
        setLineItemPacing: (lineItemPacing: LineItemPacingState | null) =>
          set(state => void (state.lineItemPacing = lineItemPacing)),
        setHighlightedGraph: (highlightedGraph: string | null) =>
          set(state => void (state.highlightedGraph = highlightedGraph))
      }))
    ),
    {
      name: 'media-tool-pending',
      version: 1,
      storage: createJSONStorage(() => (isTestMode() ? createTestStorage() : localStorage))
    }
  )
);
