import { useEffect } from 'react';

type Props = {
  title: string;
};

export function PageTitle({ title }: Props) {
  useEffect(() => {
    document.title = title;
    return () => {
      document.title = 'Bravo';
    };
  }, [title]);

  return null;
}

export function campaignTitle(name: string | undefined) {
  return `Bravo | ${name ? `${name} | ` : ''}Campaign`;
}

export function lineItemTitle(name: string | undefined) {
  return `Bravo | ${name ? `${name} | ` : ''}Line Item`;
}

export function mediaBuyTitle(mediaBuyName: string) {
  return `Bravo | ${mediaBuyName} | Media Buy`;
}
