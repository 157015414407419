import { ZohoCampaignStatus } from 'shared/src/zoho-types';

const colors: Record<ZohoCampaignStatus, string> = {
  'Potential Opportunity': 'bg-rose-400',
  New: 'bg-pink-400',
  'Media Campaign Details': 'bg-fuchsia-400',
  'Avails/Plan Development': 'bg-purple-400',
  'Proposal Sent': 'bg-violet-400',
  'Book the Campaign': 'bg-indigo-400',
  'Traffic & Onboard': 'bg-blue-400',
  'Pixel Setup': 'bg-lightBlue-400',
  'Campaign is Live': 'bg-green-400',
  'Campaign is Paused': 'bg-teal-400',
  'Campaign Management & Reporting': 'bg-emerald-400',
  'Campaign Complete': 'bg-cyan-400',
  'Renew Campaign': 'bg-lime-400',
  'Closed (Lost)': 'bg-yellow-400',
  'Exit - Dev Only': 'bg-amber-400',
  'Campaign Approved to Launch': 'bg-orange-400',
  'Campaign Cancelled': 'bg-red-400',
  'Pixel Check': 'bg-warmGray-400',
  Potential: 'bg-trueGray-400'
};

export function getColorForZohoStage(stage: string | undefined): string {
  return colors[stage as ZohoCampaignStatus] ?? 'bg-gray-400';
}

export function getPerformanceBackground(performance: number) {
  if (performance >= 1.5) {
    return colorScale[0]; // First value in the array
  } else if (performance <= 0.5) {
    return colorScale[colorScale.length - 1]; // Last value in the array
  } else {
    // Calculate the index proportional to the range
    const index = Math.round(((1.5 - performance) / (1.5 - 0.5)) * (colorScale.length - 1));
    return colorScale[index];
  }
}

export function getPerformanceColor(performance: number) {
  return performance > 1.15 || performance < 0.8 ? '#ffffff' : '#000000';
}

const colorScale = [
  '#A1740A',
  '#BA7D0B',
  '#D99606',
  '#ECAA0D',
  '#FBBF24',
  '#FCD34D',
  '#FDE68A',
  '#FEF3C7',
  '#FFFBEB',
  '#FFFFFF',
  '#F5F3FF',
  '#EDE9FE',
  '#DDD6FE',
  '#C4B5FD',
  '#A78BFA',
  '#8646F4',
  '#6D28D9',
  '#5B21B6',
  '#340B75'
];

export const d3Scale = [
  '#6F320F',
  '#954711',
  '#B65E15',
  '#D07820',
  '#E69A3E',
  '#F3B86A',
  '#F7D197',
  '#F7E2BE',
  '#F5EBDA',
  '#FFFFFF',
  '#FCFAFD',
  '#EDEAF4',
  '#D6D4E8',
  '#B6B2D8',
  '#968CC1',
  '#796DB2',
  '#623E9A',
  '#4D0B85',
  '#3B0073'
];
