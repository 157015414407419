import { CombinedMediaBuy } from '../../store/strategy-combiner';
import {
  BEESWAX_MEDIA_PLATFORM_ID,
  FACEBOOK_MEDIA_PLATFORM_ID,
  LINKEDIN_MEDIA_PLATFORM_ID,
  PINTEREST_MEDIA_PLATFORM_ID,
  TIKTOK_MEDIA_PLATFORM_ID,
  TTD_MEDIA_PLATFORM_ID,
  VIANT_MEDIA_PLATFORM_ID,
  VISTAR_MEDIA_PLATFORM_ID,
  YOUTUBE_MEDIA_PLATFORM_ID
} from 'shared/src/media-platforms';
import { BeeswaxLineItem } from 'shared/src/beeswax-types';

export function generateDeeplink(mediaBuy: CombinedMediaBuy) {
  switch (mediaBuy.media_platform_id) {
    case BEESWAX_MEDIA_PLATFORM_ID:
      return getBeeswaxPlatformLink(mediaBuy);
    case TTD_MEDIA_PLATFORM_ID:
      return getTradedeskPlatformLink(mediaBuy);
    case VIANT_MEDIA_PLATFORM_ID:
      return getViantPlatformLink(mediaBuy);
    case LINKEDIN_MEDIA_PLATFORM_ID:
      return getLinkedinPlatformLink(mediaBuy);
    case PINTEREST_MEDIA_PLATFORM_ID:
      return getPintrestPlatformLink(mediaBuy);
    case FACEBOOK_MEDIA_PLATFORM_ID:
      return getFacebookPlatformLink(mediaBuy);
    case TIKTOK_MEDIA_PLATFORM_ID:
      return getTiktokPlatformLink(mediaBuy);
    case YOUTUBE_MEDIA_PLATFORM_ID:
      return getYoutubePlatformLink(mediaBuy);
    case VISTAR_MEDIA_PLATFORM_ID:
      return getVistarPlatformLink(mediaBuy);
    default:
      return undefined;
  }
}

function getBeeswaxPlatformLink(mediaBuy: CombinedMediaBuy) {
  const data = mediaBuy.media_platform_entity?.raw_entity;
  const { advertiser_id, campaign_id, line_item_id } = BeeswaxLineItem.parse(data);
  if (advertiser_id && campaign_id && line_item_id) {
    return `https://brkthru.beeswax.com/advertisers/${advertiser_id}/campaigns/${campaign_id}/line_items/${line_item_id}/edit?tab=general&subtabs=essential`;
  }
  return undefined;
}

function getTradedeskPlatformLink(mediaBuy: CombinedMediaBuy) {
  const advertiserId = mediaBuy.media_platform_entity?.parent?.raw_entity?.['AdvertiserId'];
  const adGroupId = mediaBuy.media_platform_entity?.raw_entity?.['AdGroupId'];
  if (advertiserId && advertiserId) {
    return `https://desk.thetradedesk.com/app/advertiser/${advertiserId}/buy/adgroup/${adGroupId}`;
  }
  return undefined;
}

function getViantPlatformLink(mediaBuy: CombinedMediaBuy) {
  if (mediaBuy.media_platform_entity?.entity_id) {
    return `https://dsp.viantinc.com/campaigns/order-details/${mediaBuy.media_platform_entity?.entity_id}/`;
  }
  return undefined;
}

function getLinkedinPlatformLink(mediaBuy: CombinedMediaBuy) {
  const account = mediaBuy.media_platform_entity?.raw_entity?.['account'];
  const campaignGroup = mediaBuy.media_platform_entity?.raw_entity?.['campaignGroup'];
  if (account && campaignGroup) {
    const accountId = account.split(':').pop();
    const campaignGroupId = campaignGroup.split(':').pop();
    return `https://www.linkedin.com/campaignmanager/accounts/${accountId}/creatives?campaignGroupIds=%5B${campaignGroupId}%5D`;
  }
  return undefined;
}

function getPintrestPlatformLink(mediaBuy: CombinedMediaBuy) {
  const pinAdAccountId = mediaBuy.media_platform_entity?.raw_entity?.['ad_account_id'];
  const pinId = mediaBuy.media_platform_entity?.raw_entity?.['id'];
  if (pinAdAccountId && pinId) {
    return `https://ads.pinterest.com/advertiser/${pinAdAccountId}/reporting/ads/?adGroupIds=[${pinId}]`;
  }
  return undefined;
}

function getFacebookPlatformLink(mediaBuy: CombinedMediaBuy) {
  const fbAccountId = mediaBuy.media_platform_entity?.raw_entity?.['account_id'];
  const fbCampaignId = mediaBuy.media_platform_entity?.raw_entity?.['campaign_id'];
  const fbAdsetId = mediaBuy.media_platform_entity?.raw_entity?.['source_adset_id'];
  if (fbAccountId && fbCampaignId && fbAdsetId) {
    return `https://adsmanager.facebook.com/adsmanager/manage/ads?act=${fbAccountId}&selected_campaign_ids=${fbCampaignId}&selected_adset_ids=${fbAdsetId}`;
  }
  return undefined;
}

function getTiktokPlatformLink(mediaBuy: CombinedMediaBuy) {
  const ttAdId = mediaBuy.media_platform_entity?.raw_entity?.['advertiser_id'];
  const ttAdGroup = mediaBuy.media_platform_entity?.raw_entity?.['adgroup_id'];
  if (ttAdId && ttAdGroup) {
    return `https://ads.tiktok.com/i18n/perf/adgroup?aadvid=${ttAdId}&keyword=${ttAdGroup}&search_type=2`;
  }
  return undefined;
}

function getYoutubePlatformLink(mediaBuy: CombinedMediaBuy) {
  const campaign = mediaBuy.media_platform_entity?.raw_entity?.['campaign'];
  const baseAdGroup = mediaBuy.media_platform_entity?.raw_entity?.['base_ad_group'];
  if (campaign && baseAdGroup) {
    const campaignId = campaign.split('/').pop();
    const baseAdGroupId = baseAdGroup.split('/').pop();
    return `https://ads.google.com/aw/ads?campaignId=${campaignId}&adGroupId=${baseAdGroupId}`;
  }
  return undefined;
}

function getVistarPlatformLink(mediaBuy: CombinedMediaBuy) {
  const ioid = mediaBuy.media_platform_entity?.raw_entity?.['insertion_order_id'];
  if (ioid) {
    return `https://trafficking.vistarmedia.com/#/insertion_order/${ioid}/campaigns`;
  }
  return undefined;
}
