import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { format } from 'date-fns';
import { formatDate } from 'shared/src/date-utils';
import { formatMoney } from 'shared/src/money-utils';
import { Day } from './pacing-helpers';
import { UTCDate } from '@date-fns/utc';

export function PacingChart({ data }: { data: Day[] }) {
  const lineData = data.map(day => ({ x: format(day.day, 'yyyy-MM-dd'), y: day.cumulativeTotal }));
  const maxY = Math.max(...lineData.map(d => d.y));
  return (
    <div className="h-lg:h-[500px] h-[300px] w-full">
      <ResponsiveLine
        margin={{ top: 20, right: 20, bottom: 60, left: 90 }}
        animate
        data={[
          {
            id: 'Budget Blocks',
            data: lineData
          }
        ]}
        colors={{ scheme: 'accent' }}
        enableSlices="x"
        sliceTooltip={({ slice }) => (
          <div className="mt-10 rounded bg-white p-4 shadow-xl">
            <div className="flex justify-between">
              <div className="text-sm font-light text-gray-400">Date:</div>
              <div>{formatDate(slice.points[0].data.x as UTCDate)}</div>
            </div>
            <div className="flex justify-between ">
              <div className="mr-6 text-sm  font-light text-gray-400">Cumulative Total:</div>
              <div>{formatMoney(slice.points[0].data.y as number)}</div>
            </div>
          </div>
        )}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          useUTC: false,
          precision: 'day'
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
          type: 'linear',
          max: maxY * 1.25
        }}
        enableArea
        areaOpacity={0.9}
        axisLeft={{
          legend: 'Cumulative Spend $',
          legendOffset: -60,
          legendPosition: 'middle',
          format: (value: number) => `$${value}`
        }}
        axisBottom={{
          format: '%b %d',
          tickValues: calcTickValues(data.length),
          legend: 'Date',
          legendPosition: 'middle',
          legendOffset: 30
        }}
        enablePoints={false}
        useMesh={true}
        defs={[
          {
            id: 'greenGradient',
            type: 'linearGradient',
            colors: [
              { offset: 0, color: '#6BA292' }, // Light green at the top
              { offset: 100, color: '#2C6E49' } // Dark green at the bottom
            ]
          }
        ]}
        fill={[{ match: '*', id: 'greenGradient' }]}
      />
    </div>
  );
}

function calcTickValues(numDays: number) {
  if (numDays < 360) return 'every 1 months';
  else if (numDays < 720) return 'every 2 months';
  else if (numDays < 1800) return 'every 3 months';
  else return 'every 6 months';
}
