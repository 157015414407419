export const BEESWAX_MEDIA_PLATFORM_ID = 1;
export const TTD_MEDIA_PLATFORM_ID = 2;
export const FACEBOOK_MEDIA_PLATFORM_ID = 3;
export const TIKTOK_MEDIA_PLATFORM_ID = 5;
export const VIANT_MEDIA_PLATFORM_ID = 7;
export const YOUTUBE_MEDIA_PLATFORM_ID = 9;
export const VISTAR_MEDIA_PLATFORM_ID = 13;
export const LINKEDIN_MEDIA_PLATFORM_ID = 14;
export const PINTEREST_MEDIA_PLATFORM_ID = 15;
export const BEESWAX_TEST_ADVERTISER_ID = 90;
