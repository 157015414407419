import { CampaignMetrics, MediaBuyMetrics } from './metrics-types';
import { LineItemMetric, MediaBuyMetric } from 'shared/src/metrics-types';
import { CombinedLineItem, CombinedMediaBuy } from '../store/strategy-combiner';
import {
  calcAdjustedUnitCost,
  calcEstimatedUnits,
  calcMediaBudget,
  getAdjustedUnitPrice
} from 'shared/src/line-item-utils';
import { differenceInCalendarDays, isAfter, isBefore } from 'date-fns';
import { UnitPriceType } from 'shared/src/line-item-channels';
import { UTCDate } from '@date-fns/utc';

export function calcCampaignMetrics(
  data: LineItemMetric[],
  lineItems: CombinedLineItem[]
): CampaignMetrics | null {
  const metrics = lineItemDeliveryMetrics(data, lineItems);

  const totalTargetRevenue = metrics.map(m => m.totalTargetRevenue).reduce(sum, 0);
  const currentPlannedRevenue = metrics.map(m => m.currentPlannedRevenue).reduce(sum, 0);
  const deliveredRevenue = metrics.map(m => m.revenue).reduce(sum, 0);
  const totalPlannedSpend = metrics.map(m => m.totalPlannedSpend).reduce(sum, 0);
  const currentPlannedSpend = metrics.map(m => m.currentPlannedSpend).reduce(sum, 0);
  const mediaSpend = metrics.map(m => m.mediaSpend).reduce(sum, 0);
  const profit = deliveredRevenue - mediaSpend;
  const currentMargin = deliveredRevenue > 0 ? profit / deliveredRevenue : 0;
  const targetMargin = calcCampaignMargin(lineItems);
  const marginPerformance = currentMargin / targetMargin;

  return {
    totalTargetRevenue,
    currentPlannedRevenue,
    deliveredRevenue,
    mediaSpend,
    totalPlannedSpend,
    currentPlannedSpend,
    currentMargin,
    targetMargin,
    revenuePacing: deliveredRevenue / currentPlannedRevenue,
    spendPacing: mediaSpend / currentPlannedSpend,
    marginPerformance
  };
}

export function lineItemDeliveryMetrics(
  data: LineItemMetric[],
  lineItems: CombinedLineItem[]
): MediaBuyMetrics[] {
  return lineItems
    .map(li =>
      internalLineItemMetrics(
        li,
        calcLineItemUnitsDelivered(data, li),
        calcLineItemActualSpend(data, li)
      )
    )
    .filter(m => m != null);
}

export function calcLineItemMetrics(
  data: MediaBuyMetric[],
  lineItem: CombinedLineItem
): MediaBuyMetrics | null {
  const metrics = lineItem.media_buys
    .map(mb => calcMediaBuyMetrics(data, lineItem, mb))
    .filter(m => m != null);
  const deliveredUnits = metrics.map(m => m.deliveredUnits).reduce(sum, 0);
  const mediaSpend = metrics.map(m => m.mediaSpend).reduce(sum, 0);
  return internalLineItemMetrics(lineItem, deliveredUnits, mediaSpend);
}

function internalLineItemMetrics(
  lineItem: CombinedLineItem,
  deliveredUnits: number,
  mediaSpend: number
): MediaBuyMetrics | null {
  const result = getLineItemMetrics(lineItem);
  if (result.type === 'incomplete') return null;
  const { lineItemBudget, lineItemUnits, startDate, endDate, unitPrice, lineItemPrice } =
    result.metrics;
  const elapsedDays = diffInDays(startDate, new UTCDate());
  const lengthInDays = diffInDays(startDate, endDate);
  const elapsedProportion = calcElapsedProportion(startDate, endDate);
  const totalTargetUnits = lineItemUnits;
  const currentTargetUnits = Math.round(totalTargetUnits * elapsedProportion);
  const totalPlannedSpend = lineItemBudget;
  const currentPlannedSpend = calcCurrentPlannedSpend(
    lineItem,
    totalPlannedSpend,
    elapsedProportion
  );
  const totalTargetRevenue = unitPrice * totalTargetUnits;
  const revenue = deliveredUnits * (lineItem.unit_price || 0);
  const currentPlannedRevenue = lineItemPrice * elapsedProportion;
  const profit = revenue - mediaSpend;
  const currentMargin = revenue > 0 ? profit / revenue : 0;
  const targetMargin = lineItem.target_margin || 0;
  const adjustedUnitPrice = getAdjustedUnitPrice(lineItem) || 0;
  const adjustedUnitCost = calcAdjustedUnitCost(lineItem) || 0;
  const marginPerformance = currentMargin / targetMargin;

  return {
    id: lineItem.id,
    startDate,
    endDate,
    priceType: lineItem.unit_price_type?.name || 'CPM',
    totalTargetUnits,
    deliveredUnits,
    currentTargetUnits,
    mediaSpend,
    totalPlannedSpend,
    currentPlannedSpend,
    currentMargin,
    targetMargin,
    totalTargetRevenue,
    revenue,
    price: lineItemPrice,
    currentPlannedRevenue,
    deliveryPacing: deliveredUnits / currentTargetUnits,
    spendPacing: mediaSpend / currentPlannedSpend,
    marginPerformance,
    elapsedDays,
    elapsedProportion,
    lengthInDays,
    adjustedUnitPrice,
    unitsDeliveredProportion: deliveredUnits / totalTargetUnits,
    targetUnitsDeliveredProportion: currentTargetUnits / totalTargetUnits,
    spendProportion: mediaSpend / totalPlannedSpend,
    targetSpendProportion: currentPlannedSpend / totalPlannedSpend,
    adjustedUnitCost,
    budget: lineItemBudget
  };
}

export function calcMediaBuyMetrics(
  data: MediaBuyMetric[],
  lineItem: CombinedLineItem,
  mediaBuy: CombinedMediaBuy
): MediaBuyMetrics | null {
  const result = getLineItemMetrics(lineItem);
  if (result.type === 'incomplete') return null;
  const { lineItemBudget, lineItemUnits, unitPriceType, targetMargin, unitPrice, lineItemPrice } =
    result.metrics;
  const mediaBuyProportion = mediaBuy.budget / lineItemBudget;
  const totalTargetUnits = Math.round(lineItemUnits * mediaBuyProportion);
  const elapsedDays = diffInDays(mediaBuy.start_date, new UTCDate());
  const lengthInDays = diffInDays(mediaBuy.start_date, mediaBuy.end_date);
  const elapsedProportion = calcElapsedProportion(mediaBuy.start_date, mediaBuy.end_date);
  const currentTargetUnits = Math.round(totalTargetUnits * elapsedProportion);
  const deliveredUnits = calcMediaBuyUnitsDelivered(data, mediaBuy);
  const currentPlannedSpend =
    calcCurrentPlannedSpend(lineItem, lineItemBudget, elapsedProportion) * mediaBuyProportion;
  const totalPlannedSpend = lineItemBudget * mediaBuyProportion;

  const mediaSpend = calcMediaBuyActualSpend(data, mediaBuy);
  const revenue = deliveredUnits * unitPrice;
  const profit = revenue - mediaSpend;
  const currentMargin = revenue > 0 ? profit / revenue : 0;
  const adjustedUnitPrice = getAdjustedUnitPrice(lineItem) || 0;
  const adjustedUnitCost = calcAdjustedUnitCost(lineItem) || 0;
  const marginPerformance = currentMargin / targetMargin;
  const price = lineItemPrice * mediaBuyProportion;

  return {
    id: mediaBuy.id,
    startDate: mediaBuy.start_date,
    endDate: mediaBuy.end_date,
    priceType: unitPriceType,
    totalTargetUnits,
    currentTargetUnits,
    deliveredUnits,
    totalPlannedSpend,
    currentPlannedSpend,
    mediaSpend,
    targetMargin,
    currentMargin,
    revenue,
    price,
    totalTargetRevenue: unitPrice * totalTargetUnits,
    currentPlannedRevenue: unitPrice * currentTargetUnits,
    deliveryPacing: deliveredUnits / currentTargetUnits,
    spendPacing: mediaSpend / currentPlannedSpend,
    marginPerformance,
    elapsedDays,
    elapsedProportion: elapsedDays / lengthInDays,
    lengthInDays,
    adjustedUnitPrice,
    adjustedUnitCost,
    unitsDeliveredProportion: deliveredUnits / totalTargetUnits,
    targetUnitsDeliveredProportion: currentTargetUnits / totalTargetUnits,
    spendProportion: mediaSpend / totalPlannedSpend,
    targetSpendProportion: currentPlannedSpend / totalPlannedSpend,
    budget: mediaBuy.budget
  };
}

type IntermediateMetrics = {
  unitPriceType: UnitPriceType['name'];
  lineItemBudget: number;
  lineItemUnits: number;
  targetMargin: number;
  unitPrice: number;
  startDate: UTCDate;
  endDate: UTCDate;
  lineItemPrice: number;
};

type CompletedMetrics = {
  type: 'completed';
  metrics: IntermediateMetrics;
};

type IncompleteMetrics = {
  type: 'incomplete';
};

function getLineItemMetrics(lineItem: CombinedLineItem): CompletedMetrics | IncompleteMetrics {
  const lineItemBudget = calcMediaBudget(lineItem);
  const lineItemUnits = calcEstimatedUnits(lineItem);
  if (
    !lineItemBudget ||
    !lineItemUnits ||
    !lineItem.unit_price_type ||
    !lineItem.unit_price ||
    !lineItem.start_date ||
    !lineItem.end_date ||
    lineItem.target_margin == null ||
    lineItem.price == null
  )
    return { type: 'incomplete' };

  return {
    type: 'completed',
    metrics: {
      lineItemBudget,
      lineItemUnits,
      unitPriceType: lineItem.unit_price_type.name,
      targetMargin: lineItem.target_margin,
      unitPrice: lineItem.unit_price,
      startDate: lineItem.start_date,
      endDate: lineItem.end_date,
      lineItemPrice: lineItem.price
    }
  };
}

function calcMediaBuyUnitsDelivered(data: MediaBuyMetric[], mediaBuy: CombinedMediaBuy) {
  return data
    .filter(d => d.MEDIA_BUY_ID === mediaBuy.id)
    .map(d => d.ACTUAL_DELIVERED_UNITS)
    .reduce(sum, 0);
}

function calcMediaBuyActualSpend(data: MediaBuyMetric[], mediaBuy: CombinedMediaBuy) {
  return data
    .filter(d => d.MEDIA_BUY_ID === mediaBuy.id)
    .map(d => d.ACTUAL_MEDIA_SPEND)
    .reduce(sum, 0);
}

function calcLineItemUnitsDelivered(data: LineItemMetric[], lineItem: CombinedLineItem) {
  return data
    .filter(d => d.LINE_ITEM_ID === lineItem.id)
    .map(d => d.ACTUAL_DELIVERED_UNITS)
    .reduce(sum, 0);
}

function calcLineItemActualSpend(data: LineItemMetric[], lineItem: CombinedLineItem) {
  return data
    .filter(d => d.LINE_ITEM_ID === lineItem.id)
    .map(d => d.ACTUAL_MEDIA_SPEND)
    .reduce(sum, 0);
}

function calcElapsedProportion(startDate: UTCDate, endDate: UTCDate): number {
  const currentDate = new UTCDate();
  // Ensure the currentDate is between startDate and endDate

  if (isBefore(currentDate, startDate)) return 0;
  if (isAfter(currentDate, endDate)) return 1;

  const totalDuration = diffInDays(startDate, endDate);
  const elapsedDuration = diffInDays(startDate, currentDate);

  // Calculate the percentage elapsed
  return elapsedDuration / totalDuration;
}

// For a campaign, when calculating a time period, it is from the
// start of the first day to the end of the second day
export function diffInDays(startDate: UTCDate, endDate: UTCDate) {
  return differenceInCalendarDays(endDate, startDate) + 1;
}

function calcCampaignMargin(lineItems: CombinedLineItem[]) {
  const completedLineItems: { margin: number; price: number }[] = lineItems
    .filter(li => li.target_margin != null && li.price != null)
    .map(li => ({ margin: li.target_margin!, price: li.price! }));

  const weightedMargin = completedLineItems.map(li => li.margin * li.price).reduce(sum, 0);

  const totalPrice = completedLineItems.map(li => li.price).reduce(sum, 0);

  return weightedMargin / totalPrice;
}

function calcCurrentPlannedSpend(
  lineItem: CombinedLineItem,
  totalPlannedSpend: number,
  elapsedProportion: number
) {
  let currentPlannedSpend = 0;
  const currentDate = new UTCDate();
  // If we have custom pacing blocks defined use the blocks to calculate the current planned spend
  if ((lineItem.pacing_type === 'custom' && lineItem.pacing_details?.blocks.length) || 0 > 0) {
    lineItem.pacing_details?.blocks.forEach(block => {
      // If the block started before the current date we need to use this block
      if (isBefore(block.start_date, currentDate)) {
        // Only use the proportion of the block that has elapsed up to current date
        const blockProportion = calcElapsedProportion(block.start_date, block.end_date);
        currentPlannedSpend += block.price * blockProportion;
      }
    });
  } else {
    // With no blocks defined we just use the total planned spend
    currentPlannedSpend = totalPlannedSpend * elapsedProportion;
  }
  return currentPlannedSpend;
}

function sum(a: number, b: number) {
  return a + b;
}
