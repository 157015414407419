import * as React from 'react';
import { App } from './app';
import { createRoot } from 'react-dom/client';
import { LicenseManager } from '@ag-grid-enterprise/core';

LicenseManager.setLicenseKey(
  `Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-072507}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Brkthru}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Bravo}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Bravo}_need_to_be_licensed___{Bravo}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{27_November_2025}____[v3]_[0102]_MTc2NDIwMTYwMDAwMA==6f593b5e5947203a668e676f302f34ea`
);

const root = createRoot(document.getElementById('root')!);
root.render(<App />);
