import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate } from 'shared/src/date-utils';
import { faCalendarDays, faEye } from '@fortawesome/pro-light-svg-icons';
import { UTCDate } from '@date-fns/utc';

export function RightRailSection({ children }: { children: React.ReactNode }) {
  return <div className="flex justify-between gap-0.5">{children}</div>;
}

type RightRailItemProps = {
  label: string;
  value: string | React.ReactNode | null | undefined;
  cta?: React.ReactNode;
};

export function RightRailItem({ label, value, cta }: RightRailItemProps) {
  return (
    <div className="flex flex-1 justify-between rounded bg-gray-100 px-2 py-2">
      <div className="flex flex-col">
        <div className="text-sm font-bold">{value || '-'}</div>
        <div className="text-xs font-light text-gray-500">{label}</div>
      </div>
      {cta}
    </div>
  );
}
export function ViewPacingSchedule({ openPacing }: { openPacing: () => void }) {
  return (
    <div
      className="flex cursor-pointer items-center self-center rounded bg-blue-600 px-2 py-[2px] text-sm font-light text-white"
      onClick={openPacing}>
      <FontAwesomeIcon icon={faEye} className="mr-1" />
      <div>View Pacing Schedule</div>
    </div>
  );
}

export function DateItem({ label, date }: { label: string; date: UTCDate | undefined }) {
  return (
    <div className="rounded bg-gray-100 px-2 py-2">
      <FontAwesomeIcon className="text-gray-500" icon={faCalendarDays} />
      <div className="text-sm font-bold">{date ? formatDate(date, 'MM/dd/yyyy') : '-'}</div>
      <div className="text-xs font-light text-gray-500">{label}</div>
    </div>
  );
}
