import React, { useCallback, useMemo } from 'react';
import { Bar, Line, LinePath } from '@visx/shape';
import { curveLinear, curveMonotoneX } from '@visx/curve';
import { scaleLinear, scaleTime } from '@visx/scale';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { bisector, extent, max } from '@visx/vendor/d3-array';
import { ParentSize } from '@visx/responsive';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { motion } from 'framer-motion';
import { AnimatedGridColumns, AnimatedGridRows } from '@visx/react-spring';
import { UTCDate } from '@date-fns/utc';
import { DisplayMode } from './charts/display-mode-selector';
import { ScaleLinear, ScaleTime } from '@visx/vendor/d3-scale';
import { GraphMetric, MediaBuyGraphData } from '../metrics/metrics-types';
import { formatNumber } from './table-utils';
import { ChartTooltip } from './chart-tooltip';
import { useStore } from '../store/store';
import { generateColorMap } from '../features/media-buys/media-buy-graph-utils';

type Props = {
  displayMode: DisplayMode;
  chartType: 'spend' | 'delivery';
  graphData: MediaBuyGraphData[];
};

export function PerformanceChart({ graphData, chartType, displayMode }: Props) {
  if (graphData.length === 0) return null;
  return (
    <div className="relative flex h-full min-h-[500px] w-full min-w-0 px-8">
      <div className="min-w-0 flex-1">
        <ParentSize debounceTime={50}>
          {({ width, height }) => (
            <Graph
              width={width}
              height={height}
              graphData={graphData}
              chartType={chartType}
              displayMode={displayMode}
            />
          )}
        </ParentSize>
      </div>
    </div>
  );
}

// accessors
const bisectDate = bisector<GraphMetric, Date>(d => d.date).left;

export type TooltipData = {
  data: MediaBuyGraphData;
  d: GraphMetric;
  idx: number;
};

type GraphProps = Props & {
  width: number;
  height: number;
};

function Graph({ width, height, graphData, chartType, displayMode }: GraphProps) {
  const { showTooltip, tooltipData, hideTooltip, tooltipTop, tooltipLeft } =
    useTooltip<TooltipData>();

  const margin = { top: 20, right: 20, bottom: 30, left: 60 };

  const colorMap = generateColorMap(graphData);

  // bounds
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  // scales
  const dateScale = useMemo(
    () =>
      scaleTime({
        range: [margin.left, innerWidth + margin.left],
        domain: getTimeExtent(graphData)
      }),
    [graphData, innerWidth, margin.left]
  );
  const valueScale = useMemo(
    () =>
      scaleLinear({
        range: [innerHeight + margin.top, margin.top],
        domain: getValueExtent(graphData),
        nice: true
      }),
    [graphData, margin.top, innerHeight]
  );

  const today = dateScale(new UTCDate());

  // tooltip handler
  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 };
      const x0 = dateScale.invert(x);
      const y0 = valueScale.invert(y);
      const nearestPoint = findNearestPoint(graphData, x0, y0);

      showTooltip({
        tooltipData: nearestPoint,
        tooltipLeft: x,
        tooltipTop: valueScale(nearestPoint?.d.value || 0)
      });
    },
    [dateScale, graphData, showTooltip, valueScale]
  );

  if (graphData.length === 0 || width < 10) return null;

  return (
    <div className="absolute">
      <svg width={width} height={height}>
        <AnimatedGridRows
          left={margin.left}
          scale={valueScale}
          width={innerWidth}
          stroke={'#eff1f3'}
          animationTrajectory={'min'}
          numTicks={10}
        />
        <AnimatedGridColumns
          top={margin.top}
          scale={dateScale}
          height={innerHeight}
          stroke={'#eff1f3'}
          animationTrajectory={'min'}
          numTicks={6}
        />

        {graphData.map((d, i) => (
          <MediaBuySeries
            key={d.name}
            data={d}
            dateScale={dateScale}
            valueScale={valueScale}
            color={colorMap[d.id]}
          />
        ))}

        {today && (
          <Line
            from={{ x: today, y: margin.top }}
            to={{ x: today, y: innerHeight + margin.top }}
            stroke={'#637381'}
            strokeWidth={1}
            pointerEvents="none"
          />
        )}

        <Bar
          x={margin.left}
          y={margin.top}
          width={innerWidth}
          height={innerHeight}
          fill="transparent"
          rx={14}
          onMouseMove={handleTooltip}
          onMouseLeave={() => hideTooltip()}
        />

        <AxisBottom top={innerHeight + margin.top} scale={dateScale} numTicks={12} />

        <AxisLeft
          label={`${displayMode === 'discrete' ? 'Daily' : 'Cumulative'} ${chartType === 'spend' ? 'Spend' : 'Delivery'}`}
          labelProps={{ className: 'text-xs text-gray-700' }}
          left={margin.left}
          scale={valueScale}
          tickFormat={value => (chartType === 'spend' ? `$${value}` : formatNumber(value))}
        />

        {tooltipData && (
          <g>
            <circle
              cx={tooltipLeft}
              cy={(tooltipTop || 0) + 1}
              r={4}
              fill="black"
              fillOpacity={0.1}
              stroke="black"
              strokeOpacity={0.1}
              strokeWidth={2}
              pointerEvents="none"
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop}
              r={4}
              fill={'#75daad'}
              stroke="white"
              strokeWidth={2}
              pointerEvents="none"
            />
          </g>
        )}
      </svg>
      {tooltipData && (
        <div>
          <TooltipWithBounds
            key={Math.random()}
            top={(tooltipTop || 0) - 50}
            left={tooltipLeft || 0}
            className="z-[10000] shadow-lg">
            <ChartTooltip tooltipData={tooltipData} color={colorMap[tooltipData.data.id]} />
          </TooltipWithBounds>
        </div>
      )}
    </div>
  );
}

type MediaBuySeriesProps = {
  data: MediaBuyGraphData;
  dateScale: ScaleTime<number, number, never>;
  valueScale: ScaleLinear<number, number, never>;
  color: string;
};

function MediaBuySeries({ data, dateScale, valueScale, color }: MediaBuySeriesProps) {
  const { metrics, planned } = data;
  const highlightedGraph = useStore(state => state.highlightedGraph);
  const isHighlighted = highlightedGraph === data.id;
  return (
    <>
      <LinePath
        data={metrics}
        curve={curveMonotoneX}
        x={d => dateScale(d.date) ?? 0}
        y={d => valueScale(d.value) ?? 0}
        strokeOpacity={1}>
        {({ path }) => (
          <motion.path
            fill="none"
            initial={false}
            d={path(metrics) || ''}
            animate={{ d: path(metrics) || '' }}
            stroke={color}
            opacity={highlightedGraph == null ? 1 : isHighlighted ? 1 : 0.5}
            strokeWidth={isHighlighted ? 3 : 2}
          />
        )}
      </LinePath>
      <LinePath
        data={planned}
        curve={curveLinear}
        x={d => dateScale(d.date) ?? 0}
        y={d => valueScale(d.value) ?? 0}>
        {({ path }) => (
          <motion.path
            fill="none"
            initial={false}
            d={path(planned) || ''}
            animate={{ d: path(planned) || '' }}
            stroke={color}
            style={{ zIndex: isHighlighted ? 300 : 200 }}
            opacity={highlightedGraph == null ? 1 : isHighlighted ? 1 : 0.5}
            strokeWidth={isHighlighted ? 3 : 2}
            strokeDasharray="5,2"
          />
        )}
      </LinePath>
    </>
  );
}

function getTimeExtent(graphData: MediaBuyGraphData[]) {
  const allData = graphData.flatMap(d => [...d.metrics, ...d.planned]);
  return extent(allData, d => d.date) as [Date, Date];
}

function getValueExtent(graphData: MediaBuyGraphData[]) {
  const allDataValues = graphData.flatMap(d => [...d.metrics, ...d.planned]).map(d => d.value);
  const maxVal = max(allDataValues) || 0;
  const buffer = maxVal / 5;
  return [0, maxVal + buffer];
}

function findNearestPoint(
  graphData: MediaBuyGraphData[],
  x0: Date,
  y0: number
): TooltipData | undefined {
  const points = graphData.map(data => {
    const xIdx = bisectDate(data.metrics, x0, 1);
    const d0 = data.metrics[xIdx - 1];
    const d1 = data.metrics[xIdx];
    if (d1 && d1.date) {
      const laterValue = x0.valueOf() - d0.date.valueOf() > d1.date.valueOf() - x0.valueOf();
      return { data, d: laterValue ? d1 : d0, idx: laterValue ? xIdx : xIdx - 1 };
    }
    return null;
  });

  return getClosestToValue(points.filter(Boolean), y0);
}

function getClosestToValue(points: TooltipData[], y0: number) {
  if (points.length === 0) return undefined;

  let closest = points[0];
  let smallestDiff = Math.abs(closest.d.value - y0);

  for (let i = 1; i < points.length; i++) {
    const currentDiff = Math.abs(points[i].d.value - y0);
    if (currentDiff < smallestDiff) {
      closest = points[i];
      smallestDiff = currentDiff;
    }
  }

  return closest;
}
