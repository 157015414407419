import { MediaBuyMetrics } from '../../metrics/metrics-types';

export function hasMetrics(metrics: MediaBuyMetrics[]) {
  return metrics.some(m => m.deliveredUnits > 0);
}

export function findMetrics(id: string, metrics: MediaBuyMetrics[]) {
  const metric = metrics.find(m => m.id === id);
  return metric
    ? {
        deliveryPacing: metric.deliveryPacing,
        spendPacing: metric.spendPacing,
        marginPerformance: metric.marginPerformance,
        margin: metric.currentMargin
      }
    : {
        deliveryPacing: 0,
        spendPacing: 0,
        marginPerformance: 0,
        margin: 0
      };
}
