import React from 'react';
import { trpc } from '../../utils/trpc-client';
import { SyncButton } from '../../components/sync-button';
import { isProd } from '../../utils/env-utils';

type Props = {
  zohoCampaignId: string;
  refetch: () => unknown;
};

export function SyncStrategyButton({ refetch, zohoCampaignId }: Props) {
  const { mutateAsync, isPending } = trpc.syncStrategy.useMutation();

  // Don't show the sync strategy button in production
  if (isProd) return null;

  async function refresh() {
    await mutateAsync({ id: zohoCampaignId });
    refetch();
  }

  return <SyncButton title={'Sync Strategy'} isPending={isPending} refresh={refresh} />;
}
